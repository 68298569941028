@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

.grecaptcha-badge {
  visibility: hidden !important;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

@layer base {
  h1 {
    @apply font-brand font-bold lg:leading-snug text-3xl lg:text-5xl text-primary-content-bright;
  }
  
  h2 {
    @apply font-brand font-bold text-primary-content-bright text-2xl lg:text-4xl leading-normal;
  }

  h3 {
    @apply text-lg lg:text-xl leading-relaxed;
  }
}

@layer components {
  .password-textfield {
    @apply appearance-none bg-gray-100 border-2 border-gray-600 font-mono md:pl-4 md:pr-4 md:text-2xl p-4 pl-1 pr-1 rounded-2xl text-xl tracking-normal w-full;
  }


  p {
    @apply leading-relaxed;
  }
  
}